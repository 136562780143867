import React from 'react'
import { IonList } from '@ionic/react'
import { Form, Formik } from 'formik'
import {
  SubmitButton,
  Input,
  RadioGroup,
  FormWideErrorReporter,
} from 'formik-components'
import { PhotoUploadField } from '../components'
import { defaultCheckinQuestionnaire } from 'json'
import { QUESTION_TYPE, Questionnaire } from 'types'
import { isNil, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

const propTypes = {
  questionnaire: Questionnaire.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
const defaultProps = {
  questionnaire: defaultCheckinQuestionnaire.questionnaire,
}

function QuestionnaireForm({ initialValues, questionnaire, onSubmit }) {
  const validationSchema = Yup.object(
    customValidationConfiguration(questionnaire)
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <FormWideErrorReporter />
            <IonList>
              {questionnaire.map((question, index) => {
                const {
                  constraintText,
                  fieldName,
                  options,
                  photoType,
                  questionId,
                  required,
                  title,
                  type,
                } = question
                switch (type) {
                  case QUESTION_TYPE.CHOICE:
                    return (
                      <RadioGroup
                        name={fieldName}
                        label={title}
                        key={index}
                        options={options}
                        required={required}
                        requiredIndicator="*"
                      />
                    )
                  case QUESTION_TYPE.INPUT:
                    return (
                      <Input
                        name={fieldName}
                        label={title}
                        key={index}
                        required={required}
                        requiredIndicator="*"
                        type={type}
                      />
                    )
                  case QUESTION_TYPE.PHOTO:
                    return (
                      <PhotoUploadField
                        key={index}
                        constraintText={constraintText}
                        label={title}
                        name={fieldName}
                        photoType={photoType}
                        required={required}
                        requiredIndicator="*"
                        isSubmitting={isSubmitting}
                        questionId={questionId}
                      />
                    )
                  default:
                    throw new Error(`Unknown question type [${type}]`)
                }
              })}
              <SubmitButton isSubmitting={isSubmitting}>Continue</SubmitButton>
            </IonList>
          </Form>
        )
      }}
    </Formik>
  )
}

function customValidationConfiguration(questionnaire) {
  return questionnaire.reduce(
    (
      constraints,
      { fieldName, type, title, required, constraints: questionConstraints }
    ) => {
      if (
        isNil(questionConstraints) ||
        isEmpty(questionConstraints) ||
        !required
      ) {
        return constraints
      }
      let questionSchema
      let lengthObjectType
      if (type === QUESTION_TYPE.PHOTO) {
        questionSchema = Yup.array()
        lengthObjectType = 'photos'
      } else {
        questionSchema = Yup.string()
        lengthObjectType = 'characters'
      }

      if (questionConstraints.presence) {
        let presenceMessage = `${title} can't be blank`

        if (
          typeof questionConstraints.presence === 'object' &&
          questionConstraints.presence.message
        ) {
          presenceMessage = questionConstraints.presence.message
        }
        questionSchema = questionSchema.required(presenceMessage)
      }

      if (questionConstraints.length) {
        if (questionConstraints.length.minimum) {
          const minMessage =
            questionConstraints.length.message ||
            `Minimum of ${questionConstraints.length.minimum} ${lengthObjectType} required`
          questionSchema = questionSchema.min(
            questionConstraints.length.minimum,
            minMessage
          )
        }

        if (questionConstraints.length.maximum != undefined) {
          const maxMessage =
            questionConstraints.length.message ||
            `Maximum of ${questionConstraints.length.maximum} ${lengthObjectType} allowed`
          questionSchema = questionSchema.max(
            questionConstraints.length.maximum,
            maxMessage
          )
        }
      }
      constraints[fieldName] = questionSchema
      return constraints
    },
    {}
  )
}

QuestionnaireForm.propTypes = propTypes
QuestionnaireForm.defaultProps = defaultProps

export default React.memo(QuestionnaireForm)
